import { ChangeEvent, useEffect } from 'react'
import { Controller, FieldValues, Path } from 'react-hook-form'

import {
  Accordion,
  AccordionDetails,
  Icon,
  Select,
  Switch,
  Textarea,
  Textfield
} from '@clientbase/clientbase-library'
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme
} from '@mui/material'
import { useFetch } from 'hooks/use-fetch'
import {
  constructorAutoCompleteProvider,
  constructorAutoCompleteServices
} from 'models/ServiceProvider'
import { default as DiscountsRecurrence } from 'templates/Charge/Modals/EditRecurrence/templates/Discounts'

import { default as DiscountsCharge } from 'components/Modal/ModalEditCharge/template/Discounts'
import { NatureOption } from 'components/Modal/ModalInvoice/ModalInvoice.utils'
import NumberFormatCustom from 'components/PorcentageInput/PorcentageInput'

import { OptionsAdditionalsProps } from './OptionsAdditionals.interface'
import * as S from './OptionsAdditionals.styles'

const OptionsAdditionals = <T extends FieldValues>({
  control,
  watch,
  addFieldDiscount,
  setAddFieldDiscount,
  recurrence,
  setValue,
  isEditing
}: OptionsAdditionalsProps<T>) => {
  const matches = useMediaQuery('(min-width:1280px)')
  const theme = useTheme()

  const [, resultNfse] = useFetch({
    method: 'GET',
    shouldRun: true,
    url: '/v1/nfse_issuers/autocomplete'
  })

  const [fetchServices, resultServices] = useFetch({
    method: 'GET',
    shouldRun: false,
    url: `/v1/nfse_issuers`
  })

  useEffect(() => {
    if (
      typeof watch('nfse_issuer_uuid' as Path<T>) === 'string' &&
      watch('nfse_issuer_uuid' as Path<T>) !== ''
    ) {
      fetchServices({
        paramUrl: `${watch('nfse_issuer_uuid' as Path<T>)}/services`
      })
    }
  }, [watch('nfse_issuer_uuid' as Path<T>)])

  return (
    <Grid container={true} spacing={2} mt={3}>
      <Grid item={true} xs={12}>
        <Controller
          control={control}
          name={'discountPolicy' as Path<T>}
          render={({ field }) => (
            <>
              <Accordion
                sx={{
                  boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                  borderRadius: '6px'
                }}
                expanded={field.value}
                onChange={(_, expanded) =>
                  !!setValue &&
                  setValue('discountPolicy' as Path<T>, expanded as any)
                }
              >
                <S.AccordionSummary
                  expandIcon={<Switch checked={field.value} {...field} />}
                >
                  <Typography variant="mdBold">Desconto</Typography>
                </S.AccordionSummary>
                <AccordionDetails sx={{ p: 2 }}>
                  {!recurrence && (
                    <DiscountsCharge
                      control={control}
                      addFieldDiscount={addFieldDiscount as any}
                      setAddFieldDiscount={setAddFieldDiscount as any}
                      watchAdd={watch}
                      watchEdit={watch}
                    />
                  )}

                  {recurrence && (
                    <DiscountsRecurrence
                      control={control}
                      addFieldDiscount={addFieldDiscount as any}
                      setAddFieldDiscount={setAddFieldDiscount as any}
                      watch={watch}
                    />
                  )}
                </AccordionDetails>
              </Accordion>
            </>
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          control={control}
          name={'interestPolicy' as Path<T>}
          render={({ field }) => (
            <Accordion
              sx={{
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '6px'
              }}
              expanded={field.value}
              onChange={(_, expanded) =>
                !!setValue &&
                setValue('interestPolicy' as Path<T>, expanded as any)
              }
            >
              <S.AccordionSummary
                expandIcon={<Switch checked={field.value} {...field} />}
              >
                <Typography variant="mdBold">Juros</Typography>
              </S.AccordionSummary>
              <AccordionDetails sx={{ p: 2 }}>
                <Grid container={true} rowSpacing={1} columnSpacing={2}>
                  <Grid item={true} xs={12} sm={12} lg={6}>
                    <Controller
                      control={control}
                      name={'interestFine' as Path<T>}
                      render={({
                        field: { value, ref, ...field },
                        fieldState: { error }
                      }) => (
                        <Textfield
                          inputRef={ref}
                          label="Valor da multa de mora?"
                          placeholder="1.0%"
                          value={value}
                          InputProps={{
                            inputComponent: NumberFormatCustom as any
                          }}
                          helperText={error?.message}
                          error={!!error?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} lg={6}>
                    <Controller
                      control={control}
                      name={'interestFee' as Path<T>}
                      render={({
                        field: { value, ref, ...field },
                        fieldState: { error }
                      }) => (
                        <Textfield
                          label="Valor do juros de mora?"
                          placeholder="2.0%"
                          inputRef={ref}
                          value={value}
                          InputProps={{
                            inputComponent: NumberFormatCustom as any,
                            inputProps: { isAm: true }
                          }}
                          helperText={error?.message}
                          error={!!error?.message}
                          {...field}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          control={control}
          name={'interestInvoice' as Path<T>}
          render={({ field }) => (
            <Accordion
              sx={{
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '6px'
              }}
              expanded={!!field.value}
              onChange={(_, expanded) =>
                !!setValue &&
                setValue('interestInvoice' as Path<T>, expanded as any)
              }
            >
              <S.AccordionSummary
                expandIcon={<Switch checked={field.value} {...field} />}
              >
                <Typography variant="mdBold">Nota Fiscal</Typography>
              </S.AccordionSummary>
              <AccordionDetails sx={{ p: 2 }}>
                <Grid container={true} spacing={2}>
                  <Grid item={true} xs={12} sm={12} lg={6}>
                    <Controller
                      name={'nfse_issuer_uuid' as Path<T>}
                      control={control}
                      render={({
                        field: { onChange, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          inputRef={ref}
                          label="Prestador de serviço"
                          helperText={error?.message}
                          error={!!error?.message}
                          fullWidth
                          onChange={onChange}
                          value={value}
                          displayEmpty
                        >
                          <MenuItem disabled value="" color="#C5C5C5">
                            <Typography color="#C5C5C5" fontWeight={300}>
                              Selecione um prestador de serviço
                            </Typography>
                          </MenuItem>
                          {resultNfse &&
                            constructorAutoCompleteProvider(
                              resultNfse.data
                            ).map(({ legal_name, uuid }) => (
                              <MenuItem key={uuid} value={uuid}>
                                {legal_name}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} lg={6}>
                    <Controller
                      name={'service_list_code' as Path<T>}
                      control={control}
                      render={({
                        field: { onChange, value, ref },
                        fieldState: { error }
                      }) => (
                        <Select
                          inputRef={ref}
                          label="Item de serviço"
                          helperText={error?.message}
                          error={!!error?.message}
                          fullWidth
                          onChange={onChange}
                          value={value}
                          disabled={!watch('nfse_issuer_uuid' as Path<T>)}
                          displayEmpty
                        >
                          <MenuItem disabled value="" color="#C5C5C5">
                            <Typography color="#C5C5C5" fontWeight={300}>
                              Selecione um item de serviço
                            </Typography>
                          </MenuItem>
                          {resultServices &&
                            constructorAutoCompleteServices(
                              resultServices.data
                            ).map(({ description, uuid }) => (
                              <MenuItem key={uuid} value={uuid}>
                                {description}
                              </MenuItem>
                            ))}
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12}>
                    <Controller
                      control={control}
                      name={'copy_description' as Path<T>}
                      render={({ field }) => {
                        return (
                          <Box display="flex" alignItems="center">
                            <Checkbox
                              inputRef={field.ref}
                              {...field}
                              checked={field.value || false}
                              sx={{ marginLeft: -1.4 }}
                              id="copy_description_checkbox"
                            />

                            <label
                              htmlFor="copy_description_checkbox"
                              style={{ cursor: 'pointer' }}
                            >
                              <Typography mr={1}>
                                Copiar descrição da cobrança
                              </Typography>
                            </label>

                            <Tooltip
                              arrow
                              placement="right"
                              title={
                                <Typography variant="smLight" color="white">
                                  A descrição da nota fiscal será igual a
                                  descrição da fatura na hora da emissão.
                                </Typography>
                              }
                            >
                              <Typography display="flex" alignItems="center">
                                <Icon
                                  icon="info"
                                  sx={{ fontSize: '1.2rem', color: '#BEC0BE' }}
                                />
                              </Typography>
                            </Tooltip>
                          </Box>
                        )
                      }}
                    />
                    <Controller
                      name={'descriptionInvoice' as Path<T>}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <Box>
                          <Textarea
                            placeholder="Digite aqui a descrição"
                            value={value}
                            onChange={onChange}
                            helperText={error?.message}
                            error={!!error?.message}
                            disabled={
                              watch('copy_description' as Path<T>) as boolean
                            }
                          />
                          {error?.message && (
                            <Typography
                              color="#cc2942"
                              fontSize={'12px'}
                              fontWeight={600}
                            >
                              {error.message}
                            </Typography>
                          )}
                        </Box>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} lg={6}>
                    <Controller
                      name={'issue_when' as Path<T>}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <FormControl fullWidth={true}>
                          <Typography variant="mdLight" id="Nfe">
                            Quando deseja que a NF seja emitida?
                          </Typography>

                          {error?.message && (
                            <Typography
                              color="#cc2942"
                              fontSize={'12px'}
                              fontWeight={600}
                            >
                              {error.message}
                            </Typography>
                          )}

                          <RadioGroup
                            value={value}
                            onChange={(
                              event: ChangeEvent<HTMLInputElement>,
                              value: string
                            ) => {
                              if (
                                value == 'on_billing_issued' &&
                                watch('amount_type' as Path<T>) ===
                                  'amount_paid'
                              ) {
                                !!setValue &&
                                  setValue(
                                    'amount_type' as Path<T>,
                                    'amount_issued' as any
                                  )
                              }

                              onChange(event, value)
                            }}
                            row={matches}
                            sx={{ display: 'flex' }}
                          >
                            <FormControlLabel
                              value="on_billing_issued"
                              control={<Radio />}
                              label="Quando abrir para pagamento"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#A3A6A2',
                                ...(watch('issue_when' as Path<T>) ===
                                  'on_billing_issued' && {
                                  color: '#000'
                                }),
                                '&:hover': {
                                  color: '#666'
                                },
                                [theme.breakpoints.down('lg')]: {
                                  justifyContent: 'initial'
                                }
                              }}
                            />
                            <FormControlLabel
                              value="on_billing_paid"
                              control={<Radio />}
                              label="Quando o pagamento for efetuado"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#A3A6A2',
                                ...(watch('issue_when' as Path<T>) ===
                                  'on_billing_paid' && {
                                  color: '#000'
                                }),
                                '&:hover': {
                                  color: '#666'
                                },
                                [theme.breakpoints.down('lg')]: {
                                  justifyContent: 'initial'
                                }
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={6}>
                    <Controller
                      name={'amount_type' as Path<T>}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth={true}>
                          <Typography id="amount" variant="mdLight">
                            Qual o valor da nota?
                          </Typography>

                          <RadioGroup
                            value={value}
                            onChange={onChange}
                            aria-labelledby="amount"
                            row={matches}
                            sx={{ display: 'flex' }}
                          >
                            {!isEditing && (
                              <FormControlLabel
                                value="amount_paid"
                                disabled={
                                  watch('issue_when' as Path<T>) ==
                                  'on_billing_issued'
                                }
                                control={<Radio />}
                                label="Valor pago"
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#A3A6A2',
                                  ...(watch('amount_type' as Path<T>) ===
                                    'amount_paid' && {
                                    color: '#000'
                                  }),
                                  '&:hover': {
                                    color: '#666'
                                  },
                                  [theme.breakpoints.down('lg')]: {
                                    justifyContent: 'initial'
                                  }
                                }}
                              />
                            )}

                            <FormControlLabel
                              value="amount_issued"
                              control={<Radio />}
                              label="Valor original"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#A3A6A2',
                                ...(watch('amount_type' as Path<T>) ===
                                  'amount_issued' && {
                                  color: '#000'
                                }),
                                '&:hover': {
                                  color: '#666'
                                },
                                [theme.breakpoints.down('lg')]: {
                                  justifyContent: 'initial'
                                }
                              }}
                            />
                            <Box
                              display="flex"
                              width="100%"
                              maxWidth={{
                                xs: '100%',
                                md: '100%',
                                lg: '55.9%'
                              }}
                            >
                              <FormControlLabel
                                value="amount_custom"
                                control={<Radio />}
                                label="Valor customizado"
                                sx={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  color: '#A3A6A2',
                                  ...(watch('amount_type' as Path<T>) ===
                                    'amount_custom' && {
                                    color: '#000'
                                  }),
                                  '&:hover': {
                                    color: '#666'
                                  },
                                  whiteSpace: 'nowrap',
                                  [theme.breakpoints.down('lg')]: {
                                    width: 'auto',
                                    justifyContent: 'initial'
                                  }
                                }}
                              />

                              <Controller
                                name={'amountCustom' as Path<T>}
                                control={control}
                                render={({
                                  field: { onChange, value, ref },
                                  fieldState: { error }
                                }) => (
                                  <Textfield
                                    inputRef={ref}
                                    customStartAdornment={
                                      <Typography mr="6px" variant="mdLight">
                                        R$
                                      </Typography>
                                    }
                                    startAdornment={true}
                                    mask="money"
                                    fullWidth
                                    onChange={onChange}
                                    value={value}
                                    helperText={
                                      watch('amount_type' as Path<T>) ===
                                        'amount_custom' && error?.message
                                    }
                                    error={!!error?.message}
                                    disabled={
                                      watch('amount_type' as Path<T>) !==
                                      'amount_custom'
                                    }
                                    sx={{ maxWidth: '250px' }}
                                  />
                                )}
                              />
                            </Box>
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={6}>
                    <Controller
                      name={'nature_operation' as Path<T>}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error }
                      }) => (
                        <Select
                          helperText={error?.message}
                          error={!!error?.message}
                          onChange={onChange}
                          label="Natureza da operação"
                          value={value}
                          displayEmpty
                          fullWidth
                        >
                          <MenuItem disabled value="" color="#C5C5C5">
                            <Typography color="#C5C5C5" fontWeight={300}>
                              Selecione uma natureza da operação
                            </Typography>
                          </MenuItem>
                          {NatureOption.map(({ label, value }) => (
                            <MenuItem key={value} value={value}>
                              {label}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                    />
                  </Grid>
                  <Grid item={true} xs={12} sm={12} md={12} lg={6}>
                    <Controller
                      name={'iss_retention' as Path<T>}
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <FormControl fullWidth={true}>
                          <Typography variant="mdLight" id="Nfe" mb="4px">
                            ISS retido na fonte
                          </Typography>
                          <RadioGroup
                            value={value}
                            onChange={onChange}
                            row
                            sx={{ display: 'flex', gap: 4.5 }}
                          >
                            <FormControlLabel
                              value="yes"
                              control={<Radio />}
                              label="Sim"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#A3A6A2',
                                ...(watch('iss_retention' as Path<T>) ===
                                  'yes' && {
                                  color: '#000'
                                }),
                                '&:hover': {
                                  color: '#666'
                                }
                              }}
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="Não"
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                color: '#A3A6A2',
                                ...(watch('iss_retention' as Path<T>) ===
                                  'no' && {
                                  color: '#000'
                                }),
                                '&:hover': {
                                  color: '#666'
                                }
                              }}
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        />
      </Grid>
      <Grid item={true} xs={12}>
        <Controller
          control={control}
          name={'interestNegativation' as Path<T>}
          render={({ field }) => (
            <Accordion
              sx={{
                boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
                borderRadius: '6px'
              }}
              expanded={field.value}
              onChange={(_, expanded) =>
                !!setValue &&
                setValue('interestNegativation' as Path<T>, expanded as any)
              }
            >
              <S.AccordionSummary
                expandIcon={<Switch checked={field.value} {...field} />}
              >
                <Typography variant="mdBold">Negativar</Typography>
              </S.AccordionSummary>
              <AccordionDetails sx={{ p: 2 }}>
                <Grid container={true} rowSpacing={1} columnSpacing={2}>
                  <Grid
                    item={true}
                    xs={12}
                    sm={12}
                    display="flex"
                    gap="8px"
                    alignItems="center"
                  >
                    <Typography>Negativar automaticamente</Typography>
                    <Box maxWidth="100px">
                      <Controller
                        control={control}
                        name={'daysToReport' as Path<T>}
                        render={({
                          field: { value, ref, ...field },
                          fieldState: { error }
                        }) => (
                          <Textfield
                            placeholder="10"
                            type="number"
                            inputRef={ref}
                            value={value}
                            helperText={error?.message}
                            error={!!error?.message}
                            {...field}
                          />
                        )}
                      />
                    </Box>
                    <Typography>após o vencimento da fatura</Typography>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          )}
        />
      </Grid>
    </Grid>
  )
}

export default OptionsAdditionals
