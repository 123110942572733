export const expirationOptions = [
  ...Array.from({ length: 59 }, (_, i) => ({
    label: `${60 - i} dias`,
    value: 60 - i
  })),
  { label: '1 dia', value: 1 }
]

export const daysOptions = [
  { label: 'Outro', value: 0 },
  { label: '1 dia', value: 1 },
  ...Array.from({ length: 59 }, (_, i) => ({
    label: `${i + 2} dias`,
    value: i + 2
  }))
]
