import React, { useContext } from 'react'

import { Box, Typography } from '@mui/material'

import AutocompleteCustomers from 'components/AutocompleteCustomers'

import { FormNegativationContext } from '../../context'
import { S } from './'
import Table from './Table'

const Selection: React.FC = () => {
  const { step, selectedCustomer, updateSelectedCustomer, updateBillings } =
    useContext(FormNegativationContext)

  if (step !== 0) {
    return null
  }

  return (
    <>
      <Typography maxWidth="830px" textAlign="center">
        Nos próximos passos, vamos orientá-lo sobre como realizar uma
        negativação por meio do <b>Boa Vista</b>. O custo desse serviço é de{' '}
        <b>R$19,90</b> e seu cliente será notificado com uma fatura atualizada
        para pagamento da dívida. Caso o pagamento não seja efetuado, o CPF/CNPJ
        do cliente será negativado no Boa Vista.
      </Typography>

      <S.Section>
        <S.Header>Seleção de cobrança</S.Header>
        <S.Content>
          <Box padding="30px 10px" boxSizing="border-box">
            <AutocompleteCustomers
              onChange={(_: React.SyntheticEvent, option: unknown) => {
                updateBillings(null)
                updateSelectedCustomer(option)
              }}
              value={selectedCustomer}
              placeholder="Procurar por nome do cliente"
            />
          </Box>
          <Table />
        </S.Content>
      </S.Section>
    </>
  )
}

export default Selection
