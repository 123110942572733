import React, { useCallback, useEffect, useState } from 'react'
import { RefCallBack } from 'react-hook-form'

import { Autocomplete, Textfield } from '@clientbase/clientbase-library'
import { Stack, Typography, useMediaQuery } from '@mui/material'
import { DataService } from 'api/DataService'

import { formatCpfCnpj, formatPhone } from 'utils'

import { theme } from 'styles/theme'

type Record = {
  label: string
  value: string
  searchText: string
}

type CustomersAutocompleRecord = {
  name: string
  nickname?: string
  uuid: string
  document?: string
  phone?: string
  email?: string
}

type AutocompleteCustomersProps = {
  onChange: any
  value: any
  nameCustomer?: string
  errorMessage?: any
  multiple?: boolean
  placeholder?: string
  disabled?: boolean
  label?: string
  inputRef?: RefCallBack
}

const AutocompleteCustomers = ({
  inputRef,
  onChange,
  value,
  errorMessage,
  placeholder,
  label,
  nameCustomer,
  disabled = false,
  multiple = false
}: AutocompleteCustomersProps) => {
  const [customers, setCustomers] = useState<Record[]>([])
  const [names, setNames] = useState<string>('')
  const match = useMediaQuery('(max-width: 600px)')

  const getAutocompleteCustomers = useCallback(async () => {
    const responseCustumersAutocomplete = await DataService({
      type: 'GET',
      url: '/v1/customers_autocomplete'
    })

    if (responseCustumersAutocomplete.status === 200) {
      const customersList = responseCustumersAutocomplete.data.records.map(
        (record: CustomersAutocompleRecord) => ({
          label: record.name,
          value: record.uuid,
          searchText: [
            record.name,
            record.nickname,
            record.document,
            record.phone,
            record.email
          ].join(' | ')
        })
      )

      setCustomers(customersList)
    }
  }, [])

  useEffect(() => {
    getAutocompleteCustomers()
  }, [])

  useEffect(() => {
    if (!nameCustomer) return

    let names = ''
    if (nameCustomer.includes(';')) {
      const ArrayUuid = nameCustomer.split(';')
      ArrayUuid.forEach((uuid: string, index: number) => {
        const customer = customers.find((customer) => customer.value === uuid)
        names = `${names}${customer?.label}${
          ArrayUuid.length !== index + 1 ? ', ' : ''
        }`
      })
    } else {
      const customer = customers.find(
        (customer) => customer.value === nameCustomer
      )
      names = customer?.label ? customer.label : nameCustomer
    }
    setNames(names)
  }, [customers])

  return nameCustomer ? (
    <Textfield
      inputRef={inputRef || null}
      name="customerUuid"
      error={!!errorMessage}
      helperText={errorMessage || ''}
      placeholder="Buscar cliente"
      value={customers.length !== 0 ? names : 'Carregando...'}
      disabled={!!names}
    />
  ) : (
    <Autocomplete
      id="customer_uuid"
      onChange={onChange}
      value={value}
      options={customers || []}
      disabled={disabled}
      autoHighlight
      multiple={multiple}
      filterOptions={(options, { inputValue }) =>
        options.filter((option) =>
          (option as Record).searchText
            .toLowerCase()
            .includes(inputValue.toLowerCase())
        )
      }
      getOptionLabel={(option) => {
        if (!option) return ''
        return (option as Record).label
      }}
      isOptionEqualToValue={(option, value) =>
        (option as Record).value === (value as Record).value
      }
      renderOption={(props, option) => {
        const optionTyped = option as Record
        const [name, nickname, document, phone, email] =
          optionTyped.searchText.split(' | ')

        return (
          <Stack
            component="li"
            gap={1}
            p={1}
            pb={1.5}
            {...props}
            sx={{
              cursor: 'pointer',
              ':hover': {
                backgroundColor: '#F2F5F2'
              },
              justifyContent: 'start !important',
              alignItems: 'start !important',
              flexWrap: 'nowrap'
            }}
            key={optionTyped.value}
          >
            <Stack
              direction={match ? 'column' : 'row'}
              gap={match ? -0.2 : 0.5}
              width="100%"
              alignItems={match ? 'start' : 'center'}
            >
              <Typography
                color={theme.palette.neutral[100]}
                fontWeight={600}
                fontSize="15px"
                whiteSpace="nowrap"
              >
                {name}
              </Typography>
              {nickname && !match && (
                <Typography fontSize="12px">&nbsp;-&nbsp;</Typography>
              )}
              {nickname && (
                <Typography fontSize="12px" color="#000" whiteSpace="nowrap">
                  {nickname}
                </Typography>
              )}
            </Stack>
            <Stack
              direction={match ? 'column' : 'row'}
              gap={0.3}
              color="#777877"
              sx={{ flexWrap: 'nowrap' }}
            >
              {[
                { value: email, formatter: (s: string) => s },
                { value: phone, formatter: (s: string) => formatPhone(s) },
                { value: document, formatter: (s: string) => formatCpfCnpj(s) }
              ]
                .filter((item) => item.value)
                .map((item, index, array) => (
                  <React.Fragment key={index}>
                    <Typography fontSize="12px" whiteSpace="nowrap">
                      {item.formatter(item.value)}
                    </Typography>
                    {index < array.length - 1 && !match && (
                      <Typography fontSize="12px">&nbsp;|&nbsp;</Typography>
                    )}
                  </React.Fragment>
                ))}
            </Stack>
          </Stack>
        )
      }}
      renderInput={(params) => (
        <Textfield
          inputRef={inputRef || null}
          error={!!errorMessage}
          helperText={errorMessage || ''}
          {...(label ? { label } : {})}
          {...(placeholder ? { placeholder } : {})}
          sx={
            multiple
              ? {
                  '& .MuiInputBase-root': {
                    height: '100%',
                    minHeight: '42px'
                  }
                }
              : undefined
          }
          {...params}
        />
      )}
    />
  )
}

export default AutocompleteCustomers
