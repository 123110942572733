import React, { useEffect, useState } from 'react'
import { Controller, FieldValues, Path } from 'react-hook-form'

import { Button, Icon, Select, Textfield } from '@clientbase/clientbase-library'
import {
  Box,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Typography,
  useMediaQuery
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import NumberFormatCustom from 'components/PorcentageInput/PorcentageInput'

import { formatMoney, formatCentsToSendToBackEnd, onlyNumber } from 'utils'

import { theme } from 'styles/theme'

import { DiscountsParams } from './Discounts.interface'
import {
  days as daysMap,
  extractUuid,
  filterDiscountsToDays,
  handleAddedError,
  handleRemoveError
} from './Discounts.utils'

const Discounts = <T extends FieldValues>({
  watch,
  control,
  setAddFieldDiscount,
  addFieldDiscount
}: DiscountsParams<T>) => {
  const discountPolicy = watch('discountPolicy' as Path<T>)
  const amount = watch('amount' as Path<T>)

  const isValidateAmount = (value: string, isPercent?: boolean) => {
    if (isPercent) {
      if (!value) {
        return 'Campo obrigatório.'
      }
      if (Number(value) === 0) {
        return 'A porcentagem do desconto deve ser maior que 0%'
      }
      if (Number(value) > 90) {
        return 'A porcentagem do desconto deve ser menor ou igual a 90% do valor.'
      }
    }
    if (Number(value) > Number(amount)) {
      return 'O valor do desconto deve ser menor que o da fatura.'
    } else if (value === '') {
      return 'O campo não pode estar vazio.'
    } else if (value === undefined) {
      return undefined
    } else if (Number(value) > Number(amount) * 0.9) {
      return 'O valor do desconto deve ser menor ou igual a 90% do valor.'
    }
    return undefined
  }

  const handleChangeInputDay = (
    valueOld: string,
    valueNew: string,
    uuid: string
  ) => {
    const uuidsToRemoveErrors: string[] = []
    const uuidsToAddErrors: string[] = []

    const fieldsToRemoveErrors = filterDiscountsToDays(
      addFieldDiscount,
      valueOld
    )

    !!fieldsToRemoveErrors &&
      extractUuid(fieldsToRemoveErrors, uuidsToRemoveErrors)

    const fieldsToAddErrors = filterDiscountsToDays(addFieldDiscount, valueNew)
    const addErrorOnChange =
      fieldsToAddErrors.filter((obj) => Number(obj.days) === Number(valueNew))
        .length > 0
        ? true
        : false

    addErrorOnChange && uuidsToAddErrors.push(uuid)
    !!fieldsToAddErrors && extractUuid(fieldsToAddErrors, uuidsToAddErrors)

    const findCurrentUuid =
      uuidsToRemoveErrors.find((uuidLocal) => uuidLocal === uuid) || []

    if (uuidsToRemoveErrors.length === 2 && findCurrentUuid?.length > 1) {
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          return { ...handleRemoveError(obj, 'days') }
        })
      })
    }
    if (uuidsToAddErrors.length > 0) {
      setAddFieldDiscount((current) => {
        return current.map((obj) => {
          if (uuidsToAddErrors.find((uuid) => uuid === obj.uuid)) {
            return {
              ...handleAddedError(
                obj,
                'days',
                'O dia do desconto deve ser diferente do dia já selecionado.'
              )
            }
          }
          return { ...obj }
        })
      })
    }
    setAddFieldDiscount((current) => {
      return current.map((obj) => {
        if (obj.uuid === uuid) {
          return {
            ...obj,
            days: valueNew,
            errors: addErrorOnChange
              ? { ...obj.errors }
              : { ...obj.errors, days: undefined }
          }
        }
        return { ...obj }
      })
    })
  }
  const renderDeleteIcon = (uuid: string) => {
    return (
      <Grid item={true} xs={12} sm={12} lg={0.5} alignSelf="center">
        <Button
          icon="delete"
          fullWidth
          variantButton="gray"
          onClick={() => deleteDiscount(uuid)}
        ></Button>
      </Grid>
    )
  }

  const deleteDiscount = (uuid: string) => {
    setAddFieldDiscount((current) => current.filter((obj) => obj.uuid !== uuid))
  }
  const [localAmount, setLocalAmount] = useState<string>()
  useEffect(() => {
    setLocalAmount(watch('amount' as Path<T>) as string)
  }, [watch(`amount` as Path<T>)])
  const match = useMediaQuery('(max-width: 1279px)')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [discountObj, _setDiscountObj] = useState<
    { index: number; value: string }[]
  >([])
  const handleSetDiscountObj = (newValue: { index: number; value: string }) => {
    discountObj[newValue.index] = newValue
  }
  const calculateDiscount = (
    amount: string,
    discount: string,
    isPercent: boolean
  ) => {
    const amountInCents = Number(formatCentsToSendToBackEnd(amount))
    if (!isPercent) {
      const discountInCents = Number(
        formatCentsToSendToBackEnd(onlyNumber(discount?.toString()))
      )
      const finalAmount = amountInCents - discountInCents
      return 'Valor com desconto: ' + formatMoney(finalAmount)
    } else {
      const discountPercent = Number(discount?.toString())
      const discountValue = amountInCents * (discountPercent / 100)
      const finalAmount = amountInCents - discountValue
      return 'Valor com desconto: ' + formatMoney(finalAmount)
    }
  }
  useEffect(() => {
    setAddFieldDiscount((current) =>
      current.map((obj) => {
        const response = isValidateAmount(obj.amount as string)
        if (response && !obj?.errors?.amount?.isError) {
          return {
            ...obj,
            errors: {
              ...obj.errors,
              amount: { message: response, isError: true }
            }
          }
        } else if (obj?.errors?.amount?.isError) {
          return { ...obj }
        } else {
          return { ...obj, errors: undefined }
        }
      })
    )
  }, [amount])

  return (
    <Grid container={true} rowSpacing={1} columnSpacing={2}>
      {addFieldDiscount.map(
        ({ amount, days, errors, uuid, type = '1' }, index) => (
          <React.Fragment key={index}>
            <Grid item={true} xs={12} display="flex">
              <Grid container={true} columnSpacing={1}>
                <Grid
                  item
                  justifyContent={'start'}
                  alignItems={'end'}
                  xs={12}
                  sm={12}
                  lg={2.2}
                >
                  <Controller
                    name={`isPercent-${index}` as Path<T>}
                    control={control}
                    render={() => (
                      <>
                        <Typography variant="mdLight" id="discount">
                          {type === '1' ? 'Valor ' : 'Porcentagem '}
                          do desconto?
                        </Typography>
                        <RadioGroup
                          sx={{ justifyContent: 'start', alignContent: 'end' }}
                          row
                          value={Number(type)}
                          onChange={(event) => {
                            setAddFieldDiscount((current) => {
                              return current.map((obj) => {
                                if (!localAmount || !obj.amount) {
                                  if (obj.uuid === uuid) {
                                    return { ...obj, type: event.target.value }
                                  }
                                  return { ...obj }
                                }
                                if (obj.uuid === uuid && event.target.value) {
                                  const amount =
                                    event.target.value === '0'
                                      ? (Number(
                                          formatCentsToSendToBackEnd(
                                            onlyNumber(obj?.amount as string)
                                          )
                                        ) /
                                          Number(
                                            formatCentsToSendToBackEnd(
                                              localAmount
                                            )
                                          )) *
                                        100
                                      : (
                                          (Number(obj.amount) / 100) *
                                          Number(
                                            formatCentsToSendToBackEnd(
                                              localAmount
                                            )
                                          )
                                        ).toFixed(2)

                                  return {
                                    ...obj,
                                    amount: amount.toString(),
                                    type: event.target.value
                                  }
                                }
                                return { ...obj }
                              })
                            })
                          }}
                        >
                          <FormControlLabel
                            value={1}
                            control={<Radio />}
                            label="Valor"
                          />
                          <FormControlLabel
                            value={0}
                            control={<Radio />}
                            label="Porcentagem"
                          />
                        </RadioGroup>
                      </>
                    )}
                  />
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={4.8}>
                  <Controller
                    control={control}
                    name={'discountValueField' as Path<T>}
                    render={({ field: { ref } }) => (
                      <Textfield
                        inputRef={ref}
                        sx={{
                          [theme.breakpoints.up(1200)]: {
                            marginTop: '25px'
                          }
                        }}
                        placeholder={type === '1' ? 'R$' : '%'}
                        disabled={!discountPolicy}
                        helperText={
                          discountPolicy ? errors?.amount?.message : false
                        }
                        InputProps={{
                          inputComponent:
                            type === '0'
                              ? (NumberFormatCustom as any)
                              : undefined
                        }}
                        error={
                          discountPolicy ? !!errors?.amount?.message : false
                        }
                        name={`discounts-amount-${index}`}
                        hiddenLabel
                        mask={type === '0' ? 'string' : 'money'}
                        onChange={(event) => {
                          handleSetDiscountObj({
                            index,
                            value: event.target.value
                          })
                          setAddFieldDiscount((current) => {
                            return current.map((obj) => {
                              if (obj.uuid === uuid) {
                                const response = isValidateAmount(
                                  event.target.value,
                                  obj.type === '0'
                                )
                                return {
                                  ...obj,
                                  amount:
                                    type === '1'
                                      ? formatCentsToSendToBackEnd(
                                          event.target.value.length === 1
                                            ? (
                                                Number(event.target.value) / 100
                                              ).toString()
                                            : event.target.value
                                        )
                                      : event.target.value,
                                  errors: {
                                    ...obj.errors,
                                    amount: {
                                      message: response,
                                      isError: response ? true : undefined
                                    }
                                  }
                                }
                              }
                              return { ...obj }
                            })
                          })
                        }}
                        value={amount}
                      />
                    )}
                  />
                  <Box display={'flex'} width={'100%'} justifyContent={'end'}>
                    <Typography
                      variant="mdLight"
                      color={theme.palette.neutral[400]}
                      id="discounts"
                    >
                      {localAmount && addFieldDiscount[index].amount
                        ? type === '0'
                          ? calculateDiscount(
                              localAmount,
                              addFieldDiscount[index].amount ?? '',
                              true
                            )
                          : calculateDiscount(
                              localAmount,
                              addFieldDiscount[index].amount ?? '',
                              false
                            )
                        : ''}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item={true} xs={12} sm={12} lg={4.92}>
                  <Controller
                    control={control}
                    name={'discountLimitDate' as Path<T>}
                    render={({ field: { ref } }) => (
                      <Select
                        inputRef={ref}
                        helperText={
                          errors?.days?.isError
                            ? errors?.days?.message
                            : undefined
                        }
                        error={errors?.days?.isError ? true : false}
                        fullWidth={true}
                        label="Dias antes do vencimento:"
                        name="discountDays"
                        sx={{ width: '100%' }}
                        onChange={(event) => {
                          const discount = addFieldDiscount.find(
                            (obj) => obj.uuid === uuid
                          )
                          !!discount &&
                            handleChangeInputDay(
                              discount?.days || '0',
                              event.target?.value as string,
                              uuid
                            )
                        }}
                        value={days}
                      >
                        {daysMap.map(({ label, value }, index) => (
                          <MenuItem key={index} value={value}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
              </Grid>
              {!match && renderDeleteIcon(uuid)}
            </Grid>
            {match && renderDeleteIcon(uuid)}
          </React.Fragment>
        )
      )}

      {addFieldDiscount.length !== 3 && (
        <Grid item={true} xs={12} mt="12px">
          <Box
            display="flex"
            alignItems="center"
            padding="6px 0"
            justifyContent="center"
            width="100%"
            border="1px dashed #E3E6E3"
            onClick={() => {
              setAddFieldDiscount((prevState) => [
                ...prevState,
                {
                  uuid: uuidv4(),
                  amount: undefined,
                  type: undefined,
                  days: undefined,
                  errors: undefined
                }
              ])
            }}
          >
            <Icon icon="add" /> Adicionar novo desconto
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default Discounts
