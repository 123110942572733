import moment from 'moment'
import * as yup from 'yup'

export const schemaFormEditCharge = yup.object({
  amountBilled: yup
    .string()
    .typeError('Campo obrigatório.')
    .required('Campo obrigatório.'),
  description: yup.string(),
  totalCycles: yup.number().required('Frequência obrigatória'),
  totalCyclesCustom: yup.string().when('totalCycles', {
    is: 13,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  interestNegativation: yup.boolean(),
  daysToReport: yup.string().when('interestNegativation', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  discountPolicy: yup.boolean(),
  interestPolicy: yup.boolean(),
  dueDate: yup
    .date()
    .typeError('Insira uma data válida')
    .min(
      moment(new Date()).format('YYYY-MM-DD'),
      'Selecione uma data a partir de hoje'
    )
    .required('A data de vencimento é obrigatória'),
  expirationField: yup.string() || yup.number(),
  payments: yup
    .array()
    .min(1, 'Método de pagamento obrigatório.')
    .required('Método de pagamento obrigatório.'),
  credit_card_uuid: yup.object({
    value: yup.string(),
    label: yup.string()
  }),
  interestFee: yup.string().when('interestPolicy', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),

  interestFine: yup.string().when('interestPolicy', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  max_installments: yup.number().when('payments', {
    is: (val: string[]) => val?.includes('credit_card_installments'),
    then: yup
      .number()
      .required('Número de parcelas obrigatório')
      .min(2, 'Número minimo de parcelas é 2')
      .max(12, 'Número máximo de parcelas é 12'),
    otherwise: yup.number().nullable().notRequired()
  }),
  max_installments_without_fee: yup.number().when('payments', {
    is: (val: string[]) => val?.includes('credit_card_installments'),
    then: yup
      .number()
      .required('Número de parcelas sem juros obrigatório')
      .min(1, 'Número minimo de parcelas sem juros é 0')
      .max(12, 'Número máximo de parcelas sem juros é 12'),
    otherwise: yup.number().nullable().notRequired()
  }),
  // new implementation 17/11/2023 - Notas ficais

  interestInvoice: yup.boolean(),
  copy_description: yup.boolean().nullable(),
  iss_retention: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  service_list_code: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  amountCustom: yup.string().when('amount_type', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  descriptionInvoice: yup
    .string()
    .nullable()
    .when(['interestInvoice', 'copy_description'], {
      is: (interestInvoice: boolean, copy_description: boolean) =>
        interestInvoice === true && copy_description === false,
      then: yup.string().required('Campo obrigatório.').nullable(),
      otherwise: yup.string().nullable()
    }),
  nfse_issuer_uuid: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  amount_type: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  issue_when: yup.string().when('interestInvoice', {
    is: true,
    then: yup
      .string()
      .oneOf(
        ['on_billing_issued', 'on_billing_paid'],
        'Você deve selecionar uma das opções.'
      )
      .required('Campo obrigatório.'),
    otherwise: yup.string()
  }),
  nature_operation: yup.string().when('interestInvoice', {
    is: true,
    otherwise: yup.string(),
    then: yup.string().required('Campo obrigatório.')
  }),
  frequency: yup.number()
})
type ExtraProps = {
  discountValueField: string
  discountLimitDate: string
  internalFieldDescription: string
} & { [key in `identify-value-${number}`]: string } & {
  [key in `identify-name-${number}`]: string
}
export type EditChargeSchema = yup.InferType<typeof schemaFormEditCharge> &
  ExtraProps
