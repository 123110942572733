import '@fortawesome/fontawesome-svg-core/styles.css'
import '@szhsin/react-menu/dist/index.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'
import 'react-quill/dist/quill.snow.css'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/main.css'

import { useContext, useEffect, useMemo } from 'react'
import ReactGA from 'react-ga4'
import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import { IntlProvider } from 'react-intl'
import { ReCaptchaProvider } from 'react-recaptcha-x'
import { Bounce, ToastContainer } from 'react-toastify'
import { IntercomProvider } from 'react-use-intercom'

import { config } from '@fortawesome/fontawesome-svg-core'
import { ThemeProvider } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { AuthContext, AuthProvider } from 'context/AuthContext'
import { DialogProvider } from 'context/DialogContext'
import { OnboardingProvider } from 'context/OnboardingContext'
import ptBR from 'date-fns/locale/pt-BR'
import { AppProps } from 'next/app'
import { useRouter } from 'next/router'
import { GoogleAnalytics } from 'nextjs-google-analytics'
import NextNProgress from 'nextjs-progressbar'
import Layout from 'templates/Layout/Layout'

import { theme } from 'styles/theme'

const TRACKING_ID = 'G-FS1NXR8N9Z'

import Brazil from '../../content/locales/br.json'
import English from '../../content/locales/en.json'

config.autoAddCss = false

const INTERCOM_APP_ID = 'ug0eqsuy'

export type MerchantProps = {
  merchant_user_uuid: string
  merchant_uuid: string
}

function MyApp({ Component, pageProps }: AppProps) {
  const tagManagerArgs = {
    gtmId: 'GTM-P7GRR3JZ'
  }

  const { signOut } = useContext(AuthContext)
  const { locale, defaultLocale } = useRouter()

  const messages = useMemo(() => {
    switch (locale) {
      case 'br':
        return Brazil
      case 'en':
        return English
      default:
        return Brazil
    }
  }, [locale])

  if (process.browser) {
    TagManager.initialize(tagManagerArgs)
  }

  useEffect(() => {
    hotjar.initialize(3349324, 6)
  }, [])

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID)
    ReactGA.send({
      hitType: 'pageview',
      page: '/landingpage',
      title: 'Landing Page'
    })
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        messages={messages}
        locale={locale as string}
        defaultLocale={defaultLocale}
      >
        <GoogleOAuthProvider clientId="381361225351-ssaahotgq41hbfp1fi8tsjlaib51pg9e.apps.googleusercontent.com">
          <ReCaptchaProvider siteKeyV2="6LfXTGMkAAAAAKwLHe8ppUojROsoRurk0Rk1pTFq">
            <GoogleAnalytics trackPageViews gaMeasurementId="5FECSTTR66" />
            <ToastContainer
              position="top-right"
              autoClose={3000}
              closeOnClick
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="light"
              transition={Bounce}
            />
            <LocalizationProvider
              dateAdapter={AdapterDateFns}
              adapterLocale={ptBR}
            >
              <AuthProvider>
                <IntercomProvider appId={INTERCOM_APP_ID} autoBoot>
                  <DialogProvider>
                    <OnboardingProvider>
                      <Layout>
                        <Component {...pageProps} signOut={signOut} />
                      </Layout>
                    </OnboardingProvider>
                  </DialogProvider>
                </IntercomProvider>
              </AuthProvider>
            </LocalizationProvider>

            <NextNProgress
              color={theme.palette.primary[200]}
              height={3}
              showOnShallow={true}
              startPosition={0.3}
              stopDelayMs={201}
            />
          </ReCaptchaProvider>
        </GoogleOAuthProvider>
      </IntlProvider>
    </ThemeProvider>
  )
}

export default MyApp
