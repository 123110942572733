import React, { useState } from 'react'

import { Button, Popover } from '@clientbase/clientbase-library'
import { Box, Typography } from '@mui/material'

const variants = [
  { name: 'Mês vencimento', value: '{{mes_vencimento}}/{{ano_vencimento}}' },
  {
    name: 'Mês anterior vencimento',
    value: '{{mes_anterior_vencimento}}/{{ano_anterior_vencimento}}'
  }
]

type PopoverNewVariantProps = {
  description?: string
  setDescription: (description: string) => void
}

const PopoverNewVariant = ({
  setDescription,
  description = ''
}: PopoverNewVariantProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClickVariant = (variant: string) => {
    setDescription(description + variant)
    handleClose()
  }

  const open = Boolean(anchorEl)
  const id = open ? 'popover-note' : undefined

  return (
    <Box mt={0.5} width="100%">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="smLight">
          Caracteres: {description.length}/2000
        </Typography>

        <Box display="flex" alignItems="center" gap={1}>
          <Button
            variantButton="filters"
            bgColor="transparent"
            onClick={handleClick}
            hasFocus
          >
            + Adicionar variável
          </Button>
        </Box>
      </Box>

      <Popover
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={anchorEl}
        id={id}
        open={open}
        onClose={handleClose}
      >
        <Box
          p="16px"
          width="300px"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {variants.map((variant) => (
            <Button
              key={variant.name}
              onClick={() => handleClickVariant(variant.value)}
              hasFocus
              variantButton="transparent"
            >
              {variant.name}
            </Button>
          ))}
        </Box>
      </Popover>
    </Box>
  )
}

export default PopoverNewVariant
