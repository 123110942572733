import React, { useState } from 'react'
import { Controller, FieldValues, Path } from 'react-hook-form'

import {
  Accordion,
  AccordionDetails,
  Button,
  Icon,
  Switch,
  Textfield
} from '@clientbase/clientbase-library'
import { Box, Tooltip, Typography } from '@mui/material'
import { v4 as uuidv4 } from 'uuid'

import { IdentifyInternalProps } from './IdentifyInternal.interface'
import * as S from './IdentifyInternal.styles'

const IdentifyInternal = <T extends FieldValues>({
  indetifyInternal,
  setIdentifyInternal,
  control
}: IdentifyInternalProps<T>) => {
  const [expanded, setExpanded] = useState(false)

  const deleteIdentyInternal = (uuid: string) => {
    setIdentifyInternal((current) => current.filter((obj) => obj.uuid !== uuid))
  }

  const isValidInformation = (value: string | null | undefined) => {
    if (value === '' || value === undefined || value === null) {
      return 'Campo obrigatório.'
    }
    return undefined
  }

  const handleAccordionChange = (
    _: React.ChangeEvent<object>,
    isExpanded: boolean
  ) => {
    setExpanded(isExpanded)
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px 0px rgba(0, 0, 0, 0.10)',
        borderRadius: '6px'
      }}
      expanded={expanded}
      onChange={handleAccordionChange}
    >
      <S.AccordionSummary expandIcon={<Switch checked={expanded} />}>
        <Box display="flex" alignItems="center" gap={2}>
          <Typography variant="mdBold">Identificador interno</Typography>
          <Tooltip
            enterTouchDelay={0}
            arrow
            placement="top"
            title="Os Identificadores Internos serão mostrados nos detalhes da sua cobranças e apenas você consegue ver esses campos."
          >
            <Box>
              <Icon icon="info" />
            </Box>
          </Tooltip>
        </Box>
      </S.AccordionSummary>
      <AccordionDetails>
        <S.Grid container={true} columnSpacing={2}>
          <S.Col item={true} xs={12}>
            {indetifyInternal.map(({ name, uuid, value, errors }, index) => (
              <React.Fragment key={index}>
                <S.Col item={true} xs={12}>
                  <S.Grid container={true} spacing={1}>
                    <S.Col item={true} xs={12} sm={12} lg={5.5}>
                      <Controller
                        control={control}
                        name={`identify-name-${index}` as Path<T>}
                        render={({ field: { ref } }) => (
                          <Textfield
                            inputRef={ref}
                            fullWidth
                            error={!!errors?.name?.message}
                            helperText={errors?.name?.message || undefined}
                            name={`identify-name-${index}`}
                            label="Nome do campo:"
                            placeholder="Descrição"
                            value={name}
                            onChange={(event) => {
                              setIdentifyInternal((current) => {
                                return current.map((obj) => {
                                  if (obj.uuid === uuid) {
                                    const response = isValidInformation(
                                      event.target.value
                                    )
                                    return {
                                      ...obj,
                                      name: event.target.value,
                                      errors: {
                                        ...obj.errors,
                                        name: {
                                          message: response,
                                          isError: response ? true : undefined
                                        }
                                      }
                                    }
                                  }
                                  return { ...obj }
                                })
                              })
                            }}
                          />
                        )}
                      />
                    </S.Col>
                    <S.Col item={true} xs={12} sm={12} lg={6}>
                      <Controller
                        control={control}
                        name={`identify-value-${index}` as Path<T>}
                        render={({ field: { ref } }) => (
                          <Textfield
                            inputRef={ref}
                            fullWidth
                            helperText={errors?.value?.message}
                            error={!!errors?.value?.message}
                            name={`identify-value-${index}`}
                            label="Informação:"
                            placeholder="Descrição"
                            value={value}
                            onChange={(event) => {
                              setIdentifyInternal((current) => {
                                return current.map((obj) => {
                                  if (obj.uuid === uuid) {
                                    const response = isValidInformation(
                                      event.target.value
                                    )
                                    return {
                                      ...obj,
                                      value: event.target.value,
                                      errors: {
                                        ...obj.errors,
                                        value: {
                                          message: response,
                                          isError: response ? true : undefined
                                        }
                                      }
                                    }
                                  }
                                  return { ...obj }
                                })
                              })
                            }}
                          />
                        )}
                      />
                    </S.Col>

                    <S.Col
                      item={true}
                      xs={12}
                      lg={0.5}
                      alignSelf="end"
                      sx={{ cursor: 'pointer', width: '100%' }}
                    >
                      <Button
                        icon="delete"
                        fullWidth
                        sx={{ mb: 1 }}
                        variantButton="gray"
                        onClick={() => deleteIdentyInternal(uuid || '')}
                      ></Button>
                    </S.Col>
                  </S.Grid>
                </S.Col>
              </React.Fragment>
            ))}
          </S.Col>
          {indetifyInternal.length !== 5 && (
            <S.Col
              item={true}
              xs={12}
              mt={indetifyInternal.length === 0 ? '0' : '16px'}
            >
              <S.Container
                onClick={() => {
                  setIdentifyInternal((prevState) => [
                    ...prevState,
                    { uuid: uuidv4(), name: undefined, value: undefined }
                  ])
                }}
              >
                <Icon icon="add" /> Adicionar campo
              </S.Container>
            </S.Col>
          )}
        </S.Grid>
      </AccordionDetails>
    </Accordion>
  )
}

export default IdentifyInternal
