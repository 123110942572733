import { styled } from '@mui/material'
import { DatePicker as DatePickerMui } from '@mui/x-date-pickers/DatePicker'

export const DatePicker = styled(DatePickerMui)<{ error?: boolean }>(
  ({ theme, error }: any) => ({
    marginTop: '4px',
    borderColor: error ? theme.palette.error[300] : 'inherit',
    color: error ? theme.palette.error[300] : 'inherit',
    '& .MuiOutlinedInput-root': {
      color: error ? theme.palette.error[300] : 'inherit',
      '& fieldset': {
        color: error ? theme.palette.error[300] : 'inherit',
        borderColor: error ? theme.palette.error[300] : 'inherit'
      },
      '&:hover fieldset': {
        color: error ? theme.palette.error[300] : 'inherit',
        borderColor: error ? theme.palette.error[300] : 'inherit'
      },
      '&.Mui-focused fieldset': {
        color: error ? theme.palette.error[300] : 'inherit',
        borderColor: error ? theme.palette.error[300] : 'inherit'
      }
    },
    '& .Mui-selected': {
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
        color: 'red'
      }
    }
  })
)
