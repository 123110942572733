import { createContext, useCallback, useState } from 'react'

import {
  ChargeContextProviderProps,
  ChargeContextProviderState,
  ControlledModalCharge
} from './context.interface'

export const ChargeContext = createContext({} as ChargeContextProviderState)

export function ChargeContextProvider({
  children
}: ChargeContextProviderProps) {
  const [reloadNfse, setReloadNfse] = useState(false)
  const [reloadTable, setReloadTable] = useState(false)
  const [modal, setModal] = useState<ControlledModalCharge>({
    registerReceipt: false,
    charge: false,
    chargeDetail: false,
    chargeUuid: '',
    detailInvoice: false,
    invoice: false,
    invoiceSettings: false,
    invoiceSettingsDrawer: false,
    isOpenDetails: false,
    isOpenInvoiceDetails: false,
    loading: false,
    modalNewCharge: false,
    modalNewRecurrence: false,
    modalRecurrenceDetail: false,
    modalUploadComponent: false,
    product: false,
    recurrence: false,
    recurrenceDetail: false,
    modalNegativation: false,
    modalNegativationDetail: false,
    recurrenceUuid: '',
    uuid: '',
    uuidCustomer: '',
    uuidInvoice: '',
    uuidRecurrenceDetail: ''
  } as ControlledModalCharge)

  const updateTable = () => {
    setReloadTable((prevState) => !prevState)
  }

  const updateNfse = () => {
    setReloadNfse((prevState) => !prevState)
  }

  const toogleModal = useCallback(
    (key: keyof typeof modal, uuid = '') =>
      setModal((prevState) => ({ ...prevState, [key]: !prevState[key], uuid })),
    []
  )

  return (
    <ChargeContext.Provider
      value={{
        reloadTable,
        updateTable,
        reloadNfse,
        updateNfse,
        modal,
        updateModal: setModal,
        toogleModal
      }}
    >
      {children}
    </ChargeContext.Provider>
  )
}
