import { constructorCustomer, CustomerParams } from 'models/Customers'
import { constructorDiscounts, DiscountParams } from 'models/Discounts'
import { constructorMerchant, MerchantParams } from 'models/Merchant'
import { NfseScheduled } from 'models/NFe'
import { constructorPayment, PaymentParams } from 'models/Payment'

import {
  constructorMessageTracking,
  MessageTrackingParams
} from './message_tracking'
import { constructorNotes, NotesParams } from './notes'

export const amountTypeEnum = {
  amount_paid: 'Valor pago',
  amount_issued: 'Valor original',
  amount_custom: 'Valor customizado'
}

export const natureOperationEnum = {
  tributacao_no_municipio: 'Trib. no Município',
  tributacao_fora_do_municipio: 'Trib. Fora do Município',
  isento: 'Isento',
  imune: 'Imune',
  suspensa_por_decisao_judicial: 'Susp. Decisão Judicial',
  suspensa_por_decisao_administrativa: 'Susp. Decisão Adm'
}

export const issueWhenEnum = {
  on_billing_issued: 'Quando abrir para pagamento',
  on_billing_paid: 'Quando o pagamento for efetuado'
}

export const frequencyEnum = {
  weekly: 'Semanal',
  monthly: 'Mensal',
  quarterly: 'Trimestral',
  semester: 'Semestral',
  annual: 'Anual'
}
interface BillingDocuments {
  billingUuid: string
  createdAt: string
  description: string
  docFileName: string
  docFileSize: string
  docFileUrl: string
  docType: string
  updatedAt: string
  uuid: string
}

interface Historic {
  event: string
  description: string
  date_change: string
  user: string
}

export interface CreditCardCharges {
  status: string
  description: string
  chargedAt: string
}

export interface Negativation {
  days_to_report: number
  negativation: {
    uuid: string
    status: string
    status_detail: any
    created_at: string
    updated_at: string
    customer_uuid: string
    merchant_uuid: string
    billing_uuid: string
  }
}

export interface InvoiceParams {
  amountBilled: string
  amountPaid: string
  amountUpdated: number
  antecipated: boolean
  createdAt: string
  customer: CustomerParams
  customerUuid: string
  datePaid: string
  description: string
  dirty: boolean
  discountAmount: string
  discountDays: number
  discountPolicy: string
  discounts: DiscountParams[]
  dueDate: string
  expirationField: string | number
  interestPolicy: string
  logoUrl: string
  merchant: MerchantParams
  merchantUuid: string
  paymentType: string
  payments: PaymentParams[]
  recurrenceCycle: number
  status: string
  updatedAt: string
  uuid: string
  recurrenceUuid: string
  smsInvoiceStatus: string
  emailInvoiceStatus: string
  notes: NotesParams[]
  messageTracking: MessageTrackingParams[]
  viewed: number
  billingDocuments: BillingDocuments[]
  historic: Historic[]
  creditCardCharges: CreditCardCharges[]
  interestFee: string
  interestFine: string
  nfsesScheduled?: NfseScheduled
  nfse_issuer_uuid?: string
  nfse_uuid?: string
  nfsePolicy?: string
  metadata: any
  negativation: Negativation
}

function constructorBillingDocuments(data: any) {
  return data.map((billing: any) => ({
    billingUuid: billing.billing_uuid,
    createdAt: billing.created_at,
    description: billing.description,
    docFileName: billing.doc_file_name,
    docFileSize: billing.doc_file_size,
    docFileUrl: billing.doc_file_url,
    docType: billing.doc_type,
    updatedAt: billing.updated_at,
    uuid: billing.uuid
  }))
}

function constructorCreditCardCharges(data: any) {
  return data.map((creditCardCharge: any) => ({
    status: creditCardCharge.status,
    description: creditCardCharge.description,
    chargedAt: creditCardCharge.created_at
  }))
}

export function constructorNfseScheduled(data: NfseScheduled) {
  return {
    ...data
  } as NfseScheduled
}

export function constructorInvoice(data: any) {
  return {
    amountBilled: data?.amount_billed,
    amountPaid: data?.amount_paid,
    amountUpdated: data?.amount_updated,
    antecipated: data?.antecipated,
    createdAt: data?.created_at,
    customer: constructorCustomer(data?.customer),
    customerUuid: data?.customer_uuid,
    datePaid: data?.date_paid,
    description: data?.description,
    dirty: data?.dirty,
    discountAmount: data?.discount_amount,
    discountDays: data?.discount_days,
    discountPolicy: data?.discount_policy,
    discounts: constructorDiscounts(data?.discounts),
    dueDate: data?.due_date,
    expirationField: data?.expiration_date,
    interestPolicy: data?.interest_policy,
    logoUrl: data?.logo_url,
    merchant: constructorMerchant(data?.merchant),
    merchantUuid: data?.merchant_uuid,
    paymentType: data?.payment_type,
    payments: constructorPayment(data?.payments),
    recurrenceCycle: data?.recurrence_cycle,
    status: data?.status,
    updatedAt: data?.updated_at,
    uuid: data?.uuid,
    recurrenceUuid: data?.recurrence_uuid,
    emailInvoiceStatus: data?.email_invoice_status,
    smsInvoiceStatus: data?.sms_invoice_status,
    notes: constructorNotes(data?.notes),
    messageTracking: constructorMessageTracking(data?.message_tracking),
    viewed: data?.viewed,
    billingDocuments: constructorBillingDocuments(data?.billing_documents),
    historic: data?.historic,
    creditCardCharges: constructorCreditCardCharges(data?.credit_card_charges),
    interestFee: data?.interest_fee || '1.0',
    interestFine: data?.interest_fine || '2.0',
    nfsesScheduled: data?.nfse_scheduled
      ? constructorNfseScheduled(data?.nfse_scheduled)
      : undefined,
    nfse_issuer_uuid: data?.nfse_issuer_uuid,
    nfse_uuid: data?.nfse_uuid,
    nfsePolicy: data?.nfse_policy,
    metadata: data?.metadata,
    negativation: data?.negativation
  } as InvoiceParams
}
