import React, { Fragment } from 'react'
import { Messages, View } from 'react-big-calendar'

import { Box, Radio, Typography } from '@mui/material'
import clsx from 'clsx'

import type { EventsCalendar } from '../../CalendarReceivables.interface'
import * as S from './ViewNamesGroup.styles'

interface ViewNameGroupProps {
  views: Array<View>
  view: View
  messages: Messages<EventsCalendar>
  onView: (view: View) => void
}

const ViewNamesGroup: React.FC<ViewNameGroupProps> = ({
  views: viewNames,
  messages,
  view,
  onView
}) => {
  return (
    <S.Wrapper>
      {viewNames.map((name) => (
        <S.SelectType
          key={name}
          className={clsx({ 'rbc-active': view === name })}
          onClick={() => onView(name)}
          actived={name === view}
        >
          <Fragment>
            <Box display="flex" flexDirection="column">
              <Typography variant="mdBold">{messages[name]}</Typography>
            </Box>
            <Radio sx={{ maxHeight: '1.5rem' }} checked={view === name} />
          </Fragment>
        </S.SelectType>
      ))}
    </S.Wrapper>
  )
}

export default ViewNamesGroup
