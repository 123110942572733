import { DiscountParams, constructorDiscounts } from 'models/Discounts'
import { RecurrencesParams } from 'models/Recurrences'

export interface Products {
  chargeType: string
  dueType: string
  proratedCharge: boolean
  amount: string
  createdAt: string
  description: string
  discountPolicy: string
  dueDay: number
  frequency: string
  icon: string
  interestFee: string
  interestFine: string
  interestPolicy: string
  merchantUuid: string
  name: string
  paymentType: string[]
  status: string
  totalCycles: number
  updatedAt: string
  uuid: string
  charges?: ProductCharge[]
  discounts?: DiscountParams[]
  billingDescription?: string
  documentFile?: DocumentFile
  productInstallment?: ProductInstallment
}

type DocumentFile = {
  document_fileable_type: string
  uuid: string
  doc_type: string
  status: string
  description: string
  created_at: string
  updated_at: string
  thumbnail_url: string
}

export interface ProductInstallment {
  uuid: string
  maxInstallments: number
  maxInstallmentsWithoutFee: number
  createdAt: string
  updatedAt: string
}

export interface ProductCharge {
  uuid: string
  customer: {
    uuid: string
    name: string
    document: string
  }
  description: string
  totalCycles: number
  currentCycle: number
  type: 'Billing' | 'Recurrence'
}

function constructorProductCharge(data: any): ProductCharge {
  return {
    uuid: data?.uuid,
    customer: {
      uuid: data?.customer?.uuid,
      name: data?.customer?.name,
      document: data?.customer?.uuid
    },
    description: data?.description,
    totalCycles: data?.total_cycles,
    currentCycle: data?.current_cycle,
    type: data?.type
  }
}

function contructorProductInstallment(data: any): ProductInstallment {
  return {
    uuid: data?.uuid,
    maxInstallments: data?.max_installments,
    maxInstallmentsWithoutFee: data?.max_installments_without_fee,
    createdAt: data?.created_at,
    updatedAt: data?.updated_at
  }
}

export function constructorProducts(data: any): Array<Products> {
  return data?.map((product: any) => {
    return {
      amount: product?.amount,
      createdAt: product?.created_at,
      description: product?.description,
      billingDescription: product?.billing_description,
      discountPolicy: product?.discount_policy,
      dueDay: product?.due_day,
      frequency: product?.frequency,
      icon: product?.icon,
      interestFee: product?.interest_fee,
      interestFine: product?.interest_fine,
      interestPolicy: product?.interest_policy,
      merchantUuid: product?.merchant_uuid,
      name: product?.name,
      paymentType: product?.payment_type
        ? product?.payment_type.split(';')
        : [],
      status: product?.status,
      totalCycles: product?.total_cycles,
      updatedAt: product?.updated_at,
      uuid: product?.uuid,
      charges: product?.charges
        ? product?.charges.map((charge: RecurrencesParams) =>
            constructorProductCharge(charge)
          )
        : [],
      discounts: product?.discounts
        ? constructorDiscounts(product.discounts)
        : undefined,
      chargeType: product?.charge_type,
      dueType: product?.due_type,
      proratedCharge: product?.prorated_charge,
      documentFile: product?.document_file,
      productInstallment: contructorProductInstallment(
        product?.product_installment
      )
    } as Products
  })
}
