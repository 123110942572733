import React, { useContext } from 'react'

import { Icon } from '@clientbase/clientbase-library'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography
} from '@mui/material'
import {
  amountTypeEnum,
  issueWhenEnum,
  natureOperationEnum
} from 'models/Invoice'
import moment from 'moment'
import InvoiceIcon from 'templates/Invoice/components/InvoiceIcon'

import { formatMoney } from 'utils'

import { theme } from 'styles/theme'

import { ModalChargeDetailContext } from '../../context'
import { cardTittle } from '../../ModalChargeDetail.utils'
import { S } from './'

const NFe: React.FC = () => {
  const { invoice, nfe } = useContext(ModalChargeDetailContext)

  if (!invoice?.nfsesScheduled) {
    return null
  }

  return (
    <Accordion
      sx={{
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
        '&:before': {
          display: 'none'
        }
      }}
    >
      <AccordionSummary
        expandIcon={<Icon icon="keyboardArrowDown" />}
        aria-controls="payerDetails-content"
        id="payerDetails"
      >
        {cardTittle(
          'Nota fiscal',
          <InvoiceIcon />,
          `${theme.palette.primary[200]}`
        )}
      </AccordionSummary>
      <AccordionDetails
        sx={{
          boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.1)',
          borderTop: '1px solid #E3E6E3'
        }}
      >
        <>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin="10px 0px 10px 0px"
          >
            <Typography variant="mdLight" color="#777777">
              Prestador de serviço:
            </Typography>
            <S.Separator />
            <Typography variant="mdBold">
              {invoice?.nfsesScheduled?.nfse_issuer?.legal_name || ''}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              ISS retido:
            </Typography>
            <S.Separator />
            <Typography variant="mdBold">
              {invoice?.nfsesScheduled?.iss_retention === true
                ? 'Sim'
                : 'Não' || ''}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Emissão:
            </Typography>
            <S.Separator />
            <Typography variant="mdBold">
              {issueWhenEnum[invoice?.nfsePolicy as string] || ''}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Item de seviço:
            </Typography>
            <S.Separator />
            <Typography variant="mdBold">
              {invoice?.nfsesScheduled?.nfse_issuer_service?.code} - {''}
              {invoice?.nfsesScheduled?.nfse_issuer_service?.description}
            </Typography>
          </Box>
          <Box
            display="flex"
            gap={1.25}
            alignItems="end"
            margin={'10px 0px 10px 0px'}
          >
            <Typography variant="mdLight" color="#777777">
              Valor da nota:
            </Typography>
            <S.Separator />
            <Typography variant="mdBold">
              {amountTypeEnum[invoice?.nfsesScheduled?.amount_type as string] ||
                ''}{' '}
              {invoice?.nfsesScheduled?.amount
                ? `- ${formatMoney(Number(invoice?.nfsesScheduled?.amount))}`
                : ''}
            </Typography>
          </Box>
          {nfe?.nfse_item?.nfse_issuer_service && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Item lista de serviço:
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">
                <Typography variant="mdBold">
                  {nfe?.nfse_item?.nfse_issuer_service?.code} -{' '}
                  {nfe?.nfse_item?.nfse_issuer_service?.description}
                </Typography>
              </Typography>
            </Box>
          )}
          {nfe?.created_at && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Data/Hora emissão:
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">
                <Typography variant="mdBold">
                  {moment(nfe?.created_at).format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
              </Typography>
            </Box>
          )}
          {invoice?.nfsesScheduled?.nature_operation && (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Natureza de operação:
              </Typography>
              <S.Separator sx={{ marginTop: '4px' }} />
              <Typography variant="mdBold">
                {
                  natureOperationEnum[
                    invoice?.nfsesScheduled?.nature_operation as string
                  ]
                }
              </Typography>
            </Box>
          )}

          {invoice?.nfsesScheduled?.copy_description ? (
            <Box
              display="flex"
              gap={1.25}
              alignItems="end"
              margin={'10px 0px 10px 0px'}
            >
              <Typography variant="mdLight" color="#777777">
                Copiar descrição da cobrança
              </Typography>
              <S.Separator />
              <Typography variant="mdBold">
                <Typography variant="mdBold">
                  {invoice?.nfsesScheduled?.copy_description
                    ? 'Sim'
                    : 'Não' || ''}
                </Typography>
              </Typography>
            </Box>
          ) : (
            invoice.nfsesScheduled?.description && (
              <Box display="flex" flexDirection="column">
                <Typography variant="mdLight" color="#777777" mb="6px">
                  Descrição:
                </Typography>
                <Typography variant="mdBold">
                  {nfe?.nfse_item?.description}
                </Typography>
              </Box>
            )
          )}
        </>
      </AccordionDetails>
    </Accordion>
  )
}

export default NFe
