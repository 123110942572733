export const ChargeTabs = {
  PRODUCTS: 'produtos',
  PAYMENT_LINK: 'link_de_pagamento',
  CHARGES: 'cobrancas',
  RECURRENCES: 'recorrencias',
  TRANSFERS: 'transferencias',
  NFS: 'nfse',
  NEGATIVATION: 'negativacao'
}
export const CustomerTabs = {
  REGISTRATION_LINK: 'link_de_cadastro',
  CUSTOMERS: 'clientes',
  GROUPS: 'grupos',
  CONTRACTS: 'contratos'
}

export const ReceivablesTabs = Object.freeze({
  CALENDAR_RECEIVABLES: 'calendario_recebiveis',
  RECEIVABLES: 'saldo_liberado'
})

export const settingsTabsParams = {
  PROFILE: 'perfil',
  CHANGE_PASSWORD: 'alterar-senha',
  NOTIFICATIONS: 'notificacoes',
  BILLING_RULE: 'regua-de-cobranca',
  USERS: 'usuarios',
  CUSTOMIZE_PORTAL: 'personalizar-portal',
  BANK_ACCOUNTS: 'contas-bancarias',
  IMPORTS: 'importacoes',
  MY_PLAN: 'meu-plano',
  MY_FEES: 'minhas-taxas'
}

export const settingsTabsNumbers = {
  PROFILE: 0,
  CHANGE_PASSWORD: 1,
  NOTIFICATIONS: 2,
  BILLING_RULE: 3,
  USERS: 4,
  CUSTOMIZE_PORTAL: 5,
  BANK_ACCOUNTS: 6,
  IMPORTS: 7,
  MY_PLAN: 8,
  MY_FEES: 9
}
